<template>
    <section class="outContainer">
      <div class="back">
          <i class="el-icon-back" @click="back"></i>
          <span>{{this.$route.query.id}}预约记录</span>
      </div>
      <div class="tableContainer">
        <el-table :data="tableList" height="100%">
            <el-table-column align="center" label="用户及车辆信息" prop="name">
                <template slot-scope="scope">
                    <p>用户名：{{ scope.row.name ? scope.row.name : '--' }}</p>
                    <p>车牌号：{{ scope.row.plateNumber ? scope.row.plateNumber : '--' }}</p>
                </template>
            </el-table-column>
            <el-table-column align="center" label="车位信息" prop="unitName">
                <template slot-scope="scope">
                    <p>小区：{{ scope.row.unitName ? scope.row.unitName : '--' }}</p>
                    <p>车位：{{ scope.row.groundLockLocation ? scope.row.groundLockLocation : '--' }}</p>
                </template>
            </el-table-column>
            <el-table-column align="center" label="预约时段" prop="version">
                <template slot-scope="scope">
                    <p>开始时间：{{ scope.row.reserveStartParkingTimeStr ? scope.row.reserveStartParkingTimeStr : '--' }}</p>
                    <p>结束时间：{{ scope.row.reserveEndParkingTimeStr ? scope.row.reserveEndParkingTimeStr : '--' }}</p>
                </template>
            </el-table-column>
            <el-table-column align="center" label="预约状态" prop="statusName"></el-table-column>
            <el-table-column align="center" label="预约创建时间" prop="createTimeStr"></el-table-column>
            <el-table-column align="center" label="停放时间" prop="startParkingTimeStr">
              <template slot-scope="scope">
                  <p>{{ scope.row.status == 3 || scope.row == 4 ? scope.row.startParkingTimeStr : '--' }}</p>
              </template>
            </el-table-column>
            <el-table-column align="center" label="离开时间" prop="endParkingTimeStr">
              <template slot-scope="scope">
                  <p>{{ scope.row == 4 ? scope.row.endParkingTimeStr : '--' }}</p>
              </template>
            </el-table-column>
        </el-table>
      </div>
      <!-- 分页 -->
      <Pagination v-bind:child-msg="searchInfo" @callFather="callFather"></Pagination>
    </section>
  </template>
  
  <script>
  import Pagination from '@/components/Pagination.vue';
  import { getParkAppointRecords } from '@/api/public.js';
  import { timeChange } from '@/api/publicFun.js';
  export default {
    data() {
      return {
          searchInfo:{ // 筛选条件
              pageNo:1,
              pageSize:10,
              total:0,
              param:{
                groundLockId:'',
              }
          },
          tableList:[], // 表格数据
      }
    },
  
    components: {
        Pagination
    },
  
    computed: {},
  
    mounted() {
        this.searchInfo.param.groundLockId = this.$route.query.id;
        this.init();
    },
  
    methods: {
      // 返回上一页
      back(){
        this.$router.go(-1)
      },
      // 获取表格数据
      init(){
        getParkAppointRecords(this.searchInfo).then(res=>{
            if(res.success){
                this.tableList = res.data.list;
                this.searchInfo.total = res.data.total;
                this.tableList.forEach(element => {
                    element.createTimeStr = timeChange(element.createTime,'seconds');
                    element.reserveStartParkingTimeStr = timeChange(element.reserveStartParkingTime,'seconds');
                    element.reserveEndParkingTimeStr = timeChange(element.reserveEndParkingTime,'seconds');
                    element.startParkingTimeStr = timeChange(element.startParkingTime,'seconds');
                    element.endParkingTimeStr = timeChange(element.endParkingTime,'seconds');
                });
            }else{
                this.tableList = [];
                this.searchInfo.total = 0;
            }
        })
      },
      // 分页操作事件
      callFather(parm){
        this.searchInfo.pageNo = parm.currentPage;
        this.init();
      },
    },
  };
  </script>
  <style scoped></style>
  